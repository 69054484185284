import React from 'react';
import snowflakeImage from './img/Snowflake.png'; // 원하는 이미지로 바꿔주세요
import './Snowflake.css';

const Snowflake = () => {
  const style = {
    position: 'fixed', // `absolute` 대신 `fixed`를 사용하여 눈송이가 항상 같은 위치에 있도록 합니다.
    top: Math.random() * window.innerHeight,
    left: Math.random() * window.innerWidth,
    animationDuration: `${Math.random() * 3 + 2}s`,
    animationDelay: `${Math.random() * 3}s`,
    zIndex: 0,  // Add this line
  };

  return (
    <img src={snowflakeImage} className="snowflake" style={style} alt="snowflake" />
  );
};

export default Snowflake;
