import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCq0C6hursKfGpyIqLkvRCYKK-ISdt8PCM",
  authDomain: "loesuyfrance.firebaseapp.com",
  projectId: "loesuyfrance",
  storageBucket: "loesuyfrance.appspot.com",
  messagingSenderId: "891498672243",
  appId: "1:891498672243:web:0c5c276d38fa1b00097a06",
  measurementId: "G-CH8DNS12MP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
