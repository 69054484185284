import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TabBar from './TabBar';
import Home from './Home';
import Design from './Design';
import Works from './Works';
import Booking from './Booking';
import Info from './Info';
import ScrollToTop from './ScrollToTop';
import './App.css';
import './fonts/font.css'; // 새로 작성한 font.css 파일을 추가합니다.


function App() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Info" element={<Info />} />
            <Route path="/design" element={<Design />} />
            <Route path="/works" element={<Works />} />
            <Route path="/booking" element={<Booking />} />            
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
