import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable, listAll } from 'firebase/storage';
import { storage } from './firebase';
import './Works.css';
import { useNavigate } from 'react-router-dom';

const Works = () => {
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null); // 선택된 이미지의 인덱스
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchEndX < touchStartX) {
      nextImage(); // 오른쪽으로 스와이프
    }

    if (touchEndX > touchStartX) {
      prevImage(); // 왼쪽으로 스와이프
    }
  };

  const handleChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const storageRef = ref(storage, `works/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on('state_changed',
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
        });
      }
    );
  };

  // 페이지에 처음 접속했을 때 이미지 URL들을 가져와서 상태에 저장합니다.
  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const imagesRef = ref(storage, 'works');
        const fileList = await listAll(imagesRef);
        const urls = await Promise.all(
          fileList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            return downloadURL;
          })
        );
        setImageUrls(urls);
      } catch (error) {
        console.log('Error fetching image URLs: ', error);
      }
    };

    fetchImageUrls();
  }, []);

  // 사진 선택 및 Booking.js로 이동하는 함수
  const handleImageSelection = (selectedImageUrl) => {
    navigate(`/booking?imageUrl=${encodeURIComponent(selectedImageUrl)}`);
  };

  // 모달 열기
  const openModal = (imageUrl, index) => {
    setSelectedImageUrl(imageUrl);
    setSelectedIndex(index);
    setIsModalOpen(true);
  };
    
  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 다음 사진으로 이동
  const nextImage = () => {
    const nextIndex = (selectedIndex + 1) % imageUrls.length;
    setSelectedImageUrl(imageUrls[nextIndex]);
    setSelectedIndex(nextIndex);
  };  

  // 이전 사진으로 이동
  const prevImage = () => {
    const prevIndex = selectedIndex === 0 ? imageUrls.length - 1 : selectedIndex - 1;
    setSelectedImageUrl(imageUrls[prevIndex]);
    setSelectedIndex(prevIndex);
  };

  return (
    <div className='centerd-container'>
      <div>
        {/* <div className="upload-container">
          <input type="file" onChange={handleChange} />
          <button className="upload-button" onClick={handleUpload}>Upload</button>
        </div> */}
        <div className="tattooDesign-container-Works">
          {/* Firebase Storage에 있는 이미지 URL들을 화면에 표시 */}
          {imageUrls.map((imageUrl, index) => (
            <div className="image-item-Works" key={index} onClick={() => openModal(imageUrl, index)}>
              <img src={imageUrl} alt={`Uploaded Image ${index + 1}`} />
            </div>
          ))}
        </div>
        {/* 업로드된 이미지를 화면에 표시 */}
        {url && (
          <img src={url} alt="Uploaded Image" className="RealTattoo-image-Works" />
        )}

        {/* 모달 내부 */}
        {isModalOpen && (
          <div className="modal-Works">
            <span className="close-Works" onClick={closeModal}>&times;</span>
            <div className="modal-content-Works">
              <button className="arrow-button-Works prev-button-Works" onClick={prevImage}>&lt;</button>
              <div className="image-container">
                <img 
                  src={selectedImageUrl} 
                  alt="Selected Image" 
                  className="modal-image-Works"/>
              </div>
              <button className="arrow-button-Works next-button-Works" onClick={nextImage}>&gt;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Works;
