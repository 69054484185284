import React from 'react';
import './TabBar.css';
import InstagramLogo from './img/instagramLogo.png';
import loesuyMainLogo from './img/loesuyMainLogo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const TabBar = ({ activeTab, setActiveTab }) => {
  const tabs = ['Home', 'Info', 'Design', 'Works', 'Booking'];
  const navigate = useNavigate(); // useNavigate 훅을 사용하여 페이지 이동을 처리합니다
  const location = useLocation(); // 현재 경로를 가져옵니다

  const instagramUrl = `https://www.instagram.com/loesuy.tat/`;

  const handleInstagramLinkClick = () => {
    // 인스타그램 URL로 이동
    window.location.href = instagramUrl;
  };

  const handleLoesuyMainLogoClick = () => {
    setActiveTab(0); // Home 탭으로 이동하도록 설정
    navigate('/'); // Home 경로로 이동합니다
  };

  return (
    <div className="tab-bar">
      <button className="loesuyMainLogo-button" onClick={handleLoesuyMainLogoClick}>
        <img src={loesuyMainLogo} alt="loesuy" className="loesuyMainLogo-image" />
      </button>
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${location.pathname === `/${tab.toLowerCase()}` ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(index);
              navigate(tab.toLowerCase()); // 해당 탭의 경로로 이동하도록 설정
            }}
            style={tab === 'Home' ? { display: 'none' } : {}}
          >
            {tab}
          </button>
        ))}
      </div>
      <button className="instagram-button" onClick={handleInstagramLinkClick}>
        <img src={InstagramLogo} alt="Instagram" />
      </button>
    </div>
  );
};

export default TabBar;
