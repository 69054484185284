import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import 'react-datepicker/dist/react-datepicker.css'; 
import './Booking.css';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from './firebase';
import axios from 'axios';
import bookingFormImg from './img/bookingFormImg.jpg';
import EmailSentSuccessfully from './img/EmailSentSuccessfully.png';

const Booking = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [imageUrl, setImageUrl] = useState(null);
  const imageName = params.get('imageName') ? decodeURIComponent(params.get('imageName')) : null;
  const [emailSuccess, setEmailSuccess] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [availability, setAvailability] = useState(null);  // 추가: 사용자의 이용 가능성 상태
  const [placement, setPlacement] = useState('');
  const [size, setSize] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [filesUrl, setFilesUrl] = useState(null); // 추가: 업로드된 파일의 URL
  const [isLoading, setIsLoading] = useState(false);
  
  // 파일 처리 함수 추가
  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        if (imageName) {
          const imageRef = ref(storage, `images/${imageName}`);
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        }
      } catch (error) {
        console.log('Error fetching image URL: ', error);
      }
    };

    fetchImageUrl();
  }, [imageName]);

  const handleCloseSuccessImage = () => {
    setEmailSuccess(false);
  };

  const handleBookingSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const uploadedUrls = [];

    for (let i = 0; i < files.length; i++) {
        const fileToUpload = files[i];
        const storageRef = ref(storage, fileToUpload.name);
        const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

        // 주의: await을 for loop 안에서 사용하면 파일은 순차적으로 업로드됩니다.
        // Promise.all()을 사용하여 모든 파일을 동시에 업로드하려면 로직을 수정해야 합니다.
        try {
            await new Promise((resolve, reject) => {
                uploadTask.on('state_changed', 
                    (snapshot) => { /* 진행 함수 */ }, 
                    (error) => { reject(error); },
                    async () => {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        uploadedUrls.push(url);
                        resolve();
                    }
                );
            });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }

    const data = {
      message: `Booking has been confirmed. \n\nDetails:\nFirst name / Last name: ${firstName}\nAge: ${lastName}\nEmail: ${email}\nPhone Number: ${phoneNumber}\nAvailability: ${availability}\nPlacement: ${placement}\Approximate Size: ${size} cm\nDescription: ${description}\nImage: ${imageUrl}`,
      fileUrl: uploadedUrls // send the URL of the uploaded file
    };

    try {
      const response = await axios.post('https://us-central1-loesuytattoo.cloudfunctions.net/sendEmail', data);
      setEmailSuccess(true);
      setTimeout(() => {
        setEmailSuccess(false);
      }, 5000); // 5초 후 이미지 숨기기
    } catch (error) {
      console.error('Error sending email: ', error);
      alert('An error occurred while sending the email: ' + error.message);
    }
    setIsLoading(false); // 로딩 완료
  };
    

  return (
    <div className="centerd-container"> 
      {emailSuccess && (
          <div className="email-success-overlay">
              <div className="email-success-modal">
                  <img src={EmailSentSuccessfully} alt="Email Sent Successfully" className="EmailSentSuccessfully"/>
              </div>
          </div>
      )}
      <div className="booking-container">
        {isLoading && <div className="loading">Sending email...</div>}
         {/* 기타 컴포넌트 내용... */}
        <img src={imageUrl ? imageUrl : bookingFormImg} alt="media" className="booking-image" />      {/* 기타 예약 관련 내용 */}
        <div className="booking-form-container">
        <div className="booking-item">
          <label htmlFor="firstName">First name / Last name</label>
          <input type="text" id="firstName" name="firstName" className="booking-input" value={firstName} onChange={e => setFirstName(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="Age">Age</label>
          <input type="age" id="age" name="age" className="booking-input" value={lastName} onChange={e => setLastName(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" className="booking-input" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="phoneNumber">Phone number <span className="gray-Text">(ex +82)</span></label>          
          <input type="tel" id="phoneNumber" name="phoneNumber" className="booking-input" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>
        <div className="booking-item"> 
            <label htmlFor="availability">Availability / Time</label>
            <DateTime selected={availability} onChange={date => setAvailability(date)}   dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm" className="booking-input" />
          </div>
        <div className="booking-item">
          <label htmlFor="placement">Placement on the body</label>
          <input type="text" id="placement" name="placement" className="booking-input" value={placement} onChange={e => setPlacement(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="size">Approximate Size (cm)</label>
          <input id="size" name="size" className="booking-input" value={size} onChange={e => setSize(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="description">Description</label>
          <textarea id="description" name="description" rows="4" className="booking-textarea" value={description} onChange={e => setDescription(e.target.value)} />
        </div>
        <div className="booking-item">
          <label htmlFor="file">File</label>
          <input type="file" id="file" name="file" multiple className="booking-input" onChange={handleFileChange} />
        </div>
          <button type="button" className="booking-submit-button" onClick={handleBookingSubmit}>SUBMIT</button>
        </div>
      </div>
    </div>
  );
};

export default Booking;
