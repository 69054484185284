import React, { useState } from 'react';
import Snowflake from './Snowflake';
import Info1 from './img/Info_1.png';
import Info2 from './img/Info_2.png';
import Info3 from './img/Info_3.png';
import Info4 from './img/Info_4.png';
import './Info.css';

const Info = () => {
  const [activeTab, setActiveTab] = useState(0);

  const snowflakes = Array.from({ length: 30 }).map((_, index) => <Snowflake key={index} />);

  return (
    <div className="content-container">
      <div className="row">
        <img className="image1" src={Info1} alt="Info_1" />
        <div className="text1">
          Hello, This is loesuy, and I am a tattoo artist from Korea. 
          I used to primarily work in Seoul, Korea, but now I am in France.
          Please check out my Instagram to see where I am currently located!
        </div>
      </div>

      <div className="row reverse">
        <img className="image2" src={Info2} alt="Info_2" />
        <div className="text2">
          As a stick and poke tattoo artist, the tattooing process takes longer compared to using a machine. 
          Please make a reservation well in advance. 
        </div>
      </div>
      <div className="text3">
          In my artwork, I typically draw women, plants, and animals.
          You can get many designs from the Design tabs!
      </div>
      <div className="row">
        <img className="image3" src={Info3} alt="Info_3" />
        <div className="text4">
          My flash design is only tattooed once per person. I never work on the same design twice.
          However, if you desire a design that I have worked on previously, you do have the option to customize it in a similar style. 
        </div>
      </div>
      <div>
        <img className="image4" src={Info4} alt="Info_4" />        
      </div>
      <div className="text5 blinking-text-Info">
          <span className='new-line2'>I’m looking forward to working with you.</span><span className='new-line4'>Thank you for considering me</span><span className='new-line'>as your tattoo artist.</span>
          <span className='new-line3'>I deeply appreciate that we both share the same taste.</span>
          <span className='new-Noline'>See you soon!</span> 
        </div>
      {snowflakes}
    </div>
  );
};

export default Info;
