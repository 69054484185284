import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable, listAll } from 'firebase/storage';
import { storage } from './firebase';
import './Design.css';
import { useNavigate } from 'react-router-dom';

const Design = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // 선택된 이미지의 인덱스
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [imageNames, setImageNames] = useState([]);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchEndX < touchStartX) {
      nextImage(); // 오른쪽으로 스와이프
    }

    if (touchEndX > touchStartX) {
      prevImage(); // 왼쪽으로 스와이프
    }
  };

  // 페이지에 처음 접속했을 때 이미지 URL들을 가져와서 상태에 저장합니다.
useEffect(() => {
  const fetchImageUrls = async () => {
    try {
      const imagesRef = ref(storage, 'images');
      const fileList = await listAll(imagesRef);

      // 수정: 파일 이름들을 저장
      const names = fileList.items.map(itemRef => itemRef.name);
      setImageNames(names);

      const urls = await Promise.all(
        fileList.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);
          return downloadURL;
        })
      );
      setImageUrls(urls);
    } catch (error) {
      console.log('Error fetching image URLs: ', error);
    }
  };

  fetchImageUrls();
}, []);

  // 모달 열기
  const openModal = (imageUrl, index) => {
    setSelectedImageUrl(imageUrl);
    setSelectedIndex(index);
    setIsModalOpen(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };

  

  // Booking.js로 이미지 이동
  const handleBooking = () => {
    if (selectedImageUrl && selectedIndex != null) {
      // 수정: 이미지 파일 이름을 전달
      navigate(`/booking?imageName=${encodeURIComponent(imageNames[selectedIndex])}`);
      closeModal();
    }
  };

  // 다음 사진으로 이동
  const nextImage = () => {
    const nextIndex = (selectedIndex + 1) % imageUrls.length;
    setSelectedImageUrl(imageUrls[nextIndex]);
    setSelectedIndex(nextIndex);
  };

  // 이전 사진으로 이동
  const prevImage = () => {
    const prevIndex = selectedIndex === 0 ? imageUrls.length - 1 : selectedIndex - 1;
    setSelectedImageUrl(imageUrls[prevIndex]);
    setSelectedIndex(prevIndex);
  };

  return (
    <div className='tattooDesign-Main-Design'>
      <div className="tattooDesign-container-Design">
        {/* Firebase Storage에 있는 이미지 URL들을 화면에 표시 */}
        {imageUrls.map((imageUrl, index) => (
          <div className="image-item-Design" key={index} onClick={() => openModal(imageUrl, index)}>
            <img src={imageUrl} alt={`Uploaded Image ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* 모달 내부 */}
      {isModalOpen && (
        <div className="modal-Design"
        // onTouchStart={handleTouchStart} 
        // onTouchMove={handleTouchMove} 
        // onTouchEnd={handleTouchEnd} 
        >
          <span className="close-Design" onClick={closeModal}>&times;</span>
          <div className="modal-content-Design">
            <button className="arrow-button-Design prev-button-Design" onClick={prevImage}>&lt;</button>
            <div className="image-container-Design">
              <img 
                src={selectedImageUrl} 
                alt="Selected Image" 
                className="modal-image-Design" />
              <div className="centered-button-container">
                <button className="booking-button-Design" onClick={handleBooking}>Booking</button>
              </div>
            </div>
            <button className="arrow-button-Design next-button-Design" onClick={nextImage}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Design;
