import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RealTottoo from './img/RealTattoo.png'; 
import tattooDesign1 from './img/tattooDesign1.png'; 
import tattooDesign2 from './img/tattooDesign2.png'; 
import tattooDesign3 from './img/tattooDesign3.png'; 
import HomeDesignMoveButton from './img/HomeDesignMoveButton.png';
import scrollDownHandBlack from './img/scrollDownHandBlack.png'; 
import scrollDownHandWhite from './img/scrollDownHandWhite.png'; 
import Snowflake from './Snowflake';
import './Home.css';

const Home = () => {
  const snowflakes = Array.from({ length: 20 }).map((_, index) => <Snowflake key={index} />);

  const [activePage, setActivePage] = useState(0);
  const [canScroll, setCanScroll] = useState(true);


  useEffect(() => {
    let startY;

    const handleScroll = (e) => {
      // canScroll 상태가 false이면 스크롤 동작을 중단
      if (!canScroll) return;

      if (e.deltaY > 0 && activePage < 3) {
          setActivePage((prev) => prev + 1);
      } else if (e.deltaY < 0 && activePage > 0) {
          setActivePage((prev) => prev - 1);
      }

      // 스크롤 동작 후 1초 동안 추가 스크롤을 막음
      setCanScroll(false);
      setTimeout(() => {
        setCanScroll(true);
      }, 1000);
  };

    const handleTouchStart = (e) => {
        startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        if (currentY < startY && activePage < 3) {
            setActivePage((prev) => prev + 1);
        } else if (currentY > startY && activePage > 0) {
            setActivePage((prev) => prev - 1);
        }
    };

    window.addEventListener('wheel', handleScroll, { passive: true });
    window.addEventListener('touchstart', handleTouchStart, { passive: true });
    window.addEventListener('touchmove', handleTouchMove, { passive: true });

    return () => {
        window.removeEventListener('wheel', handleScroll);
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
    };
}, [activePage, canScroll]);

  return (
    <div className="container">
      <div className={`page page1 ${activePage === 0 ? 'active' : activePage > 0 ? 'fade-out' : ''}`}>
        <h2 className='big-text1'>
          This is <span className="highlight">loesuy</span>,
        </h2>
        <h2 className='big-text1'>
          and I am a <span className='underline-text1'>stick and</span>
        </h2>
        <h2 className='big-text1'>
          <span className='underline-text2'>poke tattoo artist</span>
        </h2>
        <img src={scrollDownHandBlack} alt="scrollDownHandBlack" className="scrollDownHand-image blinking-text"/>
      </div>

      <div className={`page page2 ${activePage === 1 ? 'active' : activePage > 1 ? 'fade-out' : ''}`}>
        <img src={RealTottoo} alt="Real Tattoo" className="RealTattoo-image"/>
      </div>

      <div className={`page page2 ${activePage === 2 ? 'active' : activePage > 2 ? 'fade-out' : ''}`}>
        <h2 className="small-text">You can get your tattoo design</h2> 
        <div className='tattooDesign-container-Home'>
          <img src={tattooDesign1} alt="tattooDesign1" className="tattooDesign-image-Home"/>
          <img src={tattooDesign2} alt="tattooDesign2" className="tattooDesign-image-Home"/>
          <img src={tattooDesign3} alt="tattooDesign3" className="tattooDesign-image-Home"/>
        </div>
      </div>

      <div className={`page page4 ${activePage === 3 ? 'active' : ''}`}>
        <h2 className="big-text2 blinking-text">See you soon!</h2> 
        <h2 className="big-text2">I am looking forward to</h2> 
        <h2 className="big-text2">working with you</h2> 
        <div className="spacer"></div>
        <div className="button-container">
          <Link to="/design">
            <img src={HomeDesignMoveButton} alt="HomeDesignMovebitton" className="HomeDesignMovebitton-image-Home"></img>
            {/* <button className="rounded-button">Check more designs</button> */}
          </Link>        
        </div>
      </div>
    </div>
  );
};

export default Home;
